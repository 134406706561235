import React from "react"
import Fade from 'react-reveal/Fade';
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from 'gatsby-plugin-smoothscroll';
import computer from '../images/computer-monitor.svg'
import twitter from '../images/twitter.svg'
import linkedin from '../images/linkedin.svg'

const IndexPage = () => (
  <Layout>
    <SEO title="Shaun Symm, early stage startup advisor" />

      <section role="main" className="hero-wrapper flex flex-ac">
        <div className="container">
          <div className="two-grid">
            <div className="hero-content">
              <Fade left duration={1000} delay={500} distance="30px">
                <h2 className="hero-title">Hello,<br />I'm <span className="pink-text">Shaun</span></h2>
              </Fade>
              <Fade left duration={1000} delay={600} distance="40px">
                <p className="lead">I work with startups in Australia helping them with strategy, product design & product development.</p>
              </Fade>
              <div className="btn-row">
                <Fade bottom duration={1000} delay={700} distance="40px">
                  <button onClick={() => scrollTo('#about')} className="btn btn-outline">
                    Learn more
                  </button>
                </Fade>
              </div>
            </div>
            <div className="hero-right">
              <div className="video-wrapper">
                <iframe src="https://www.videoask.com/fvqomirsb"
                  allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                  width="100%"
                  height="100%"
                  style={{ border: 'none', borderRadius: '24px' }}
                  >
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container">
          <div className="mid-grid">
            <div className="mid-col" id="about">
              <Fade left duration={800} delay={500} distance="30px">
                <h3 className="hero-title">About</h3>
              </Fade>
              <Fade bottom>
                <p className="lead">I’m the managing director of Launch Lab, where we develop startups for non-technical founders in Australia.</p>

                <p>I started my professional life in marketing &amp; advertising and now help startups get started.</p>
                <p>I have significant early stage startup experience helping founders with strategy, scoping, prototyping, product design, MVP development, project management, product delivery and early stage marketing.</p>
                <p>I’m Google Ads certified and highly knowledgable about SEO. I write an award winning <a href="https://launchlab.com.au/blog/">startup blog</a> for new founders, but spend most of my time designing websites and startup products.</p>
                <p>I’ve helped build more than 30 startups and many more websites, apps and other digital products for companies like Telstra, Aussie Homeloans, Australia National University, City of Sydney &amp; NSW Government.</p>

                <p>If you’re an early stage startup founder in Australia and planning to develop a product I’d love to help. The advice I give usually revolves around:</p>
                <ul>
                  <li>the importance of running lean</li>
                  <li>the importance of user research</li>
                  <li>focus on distribution before you have a product</li>
                  <li>raising VC money is not what you should be doing ... yet</li>
                </ul>
                <p>You can read more in these <a href="https://launchlab.com.au/startup-strategy-lessons/">startup strategy lessons</a>.</p>
              </Fade>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="mid-grid">
            <div className="mid-col">
              <div className="flex flex-sb">
                <Fade bottom duration={800} delay={800} distance="40px">
                  <a href="https://launchlab.com.au" className="social-btn" title="Launch Lab website" target="_blank" rel="noreferrer">
                    <img src={computer} alt='Computer monitor - click to view Launch Lab website' />
                  </a>
                </Fade>
                <Fade bottom duration={1000} delay={1000} distance="40px">
                  <a href="https://twitter.com/shaunsymm" className="social-btn" title="Shaun's Twitter" target="_blank" rel="noreferrer">
                    <img src={twitter} alt='Twitter icon' />
                  </a>
                </Fade>
                <Fade bottom duration={1200} delay={1200} distance="40px">
                  <a href="https://www.linkedin.com/in/shaunsymm/" className="social-btn" title="Shaun's Linkedin" target="_blank" rel="noreferrer">
                    <img src={linkedin} alt='Linkedin icon' />
                  </a>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

  </Layout>
)

export default IndexPage
